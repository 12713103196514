import Head from "next/head";
import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";
import { colors } from "@mytutor/shared-react-web-components/lib/theme";
import HeadGoogleTag, {
  HeadDataLayerDimensions,
  ABTasty,
} from "@mytutor/shared-react-web-components/lib/SiteTracking";
import setReferral from "../utils/setReferral";
import Header from "@mytutor/mytutor-design-system/components/Header";
import Hero from "../components/Hero";
import StickyCTA from "../components/StickyCTA";
import CookieConsentAutoClose from "../components/CookieConsentAutoClose";
import { useInitSnowPlow, useSnowPlowPageView } from "../utils/snowplow";
import PageTitleRegistry from "../utils/snowplow/PageTitleRegistry";

const TrustpilotAndFeaturedBar = dynamic(
  () => import("../components/TrustpilotAndFeaturedBar")
);
const PopularSubjects = dynamic(() => import("../components/PopularSubjects"));
const LearningSteps = dynamic(() => import("../components/LearningSteps"));
const Feature = dynamic(() => import("../components/Feature"));
const FeaturedBar = dynamic(() => import("../components/FeaturedBar"));
const SubjectsBar = dynamic(() => import("../components/SubjectsBar"));

const FAQs = dynamic(() => import("../components/FAQs"));
const FreeStudyResources = dynamic(
  () => import("../components/FreeStudyResources")
);
const Ratings = dynamic(() => import("../components/Ratings"));
const CTABanner = dynamic(() => import("../components/CTABanner"));
const Footer = dynamic(
  () => import("@mytutor/shared-react-web-components/lib/Footer")
);

import fetchSubjects from "../utils/fetchSubjects";

export async function getStaticProps() {
  const subjects = await fetchSubjects();
  return {
    props: {
      subjects,
    },
  };
}

export default function Home({ subjects = [] }) {
  const heroEndRef = useRef<HTMLDivElement>(null);
  const [heroPosition, setHeroPosition] = useState(0);

  useEffect(() => {
    if (heroEndRef.current) {
      setHeroPosition(heroEndRef.current.offsetTop);
    }
  }, [heroEndRef]);

  useEffect(() => {
    setReferral();
  }, []);
  useInitSnowPlow();
  useSnowPlowPageView({ title: PageTitleRegistry.ZEUS_LANDING_HOME.title });

  return (
    <div>
      <Head>
        <title>
          Online Tutoring - Affordable high quality online tutors - MyTutor
        </title>
        <meta
          name="description"
          content="Online tutoring is the easy, affordable and stress-free way to find a personal tutor. Choose from personally-interviewed tutors and learn with them online."
        />
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_HOMEPAGE_URL}`}
        />
        <ABTasty />
        <HeadDataLayerDimensions pageType="homepage" />
        <HeadGoogleTag test={(process.env.APP_ENV ?? "prod") !== "prod"} />
      </Head>
      <Header />
      <main>
        <Hero
          subjects={subjects}
          header="Online tutoring that releases potential"
          subText="We can't stop you worrying about your child. But our expert tutors can help their grades and confidence soar - and help you worry a little less."
          ctaText="Get Started"
        />
        <div ref={heroEndRef} style={{ height: 0 }}></div>
        <TrustpilotAndFeaturedBar />
        <PopularSubjects />
        <LearningSteps />
        <Feature
          top
          reverseRow={false}
          title="Handpicked tutors from £25/hour"
          backgroundColour={colors.bgcolor1}
          textColour={colors.neutral8}
          paragraphSectionOne="We're very (very) picky about who we let tutor on our platform - just 1 in 8 who apply make the cut.
                  They're experts in over 30 subjects from KS2 up to GCSE and A Level.
                  Because they're from UK unis, they studied (and aced) the same courses as your teen in the last few years.
                  So they explain tricky concepts in a way teens understand - and they double as cool older role models."
          image="/landing/brand-image-for-feature-1.svg"
          imageAlt="Online lessons"
        />
        <Feature
          reverseRow
          title="Trusted by parents & teachers"
          backgroundColour={colors.bgcolor1}
          textColour={colors.neutral8}
          paragraphSectionOne="MyTutor is the UK's most trusted tutoring platform by parents.
                  We're rated 4.96/5 by students and parents from the 3.8 million (and counting!) lessons we’ve delivered so far."
          paragraphSectionTwo="And because our tutors get such good results, schools use them to support their teaching.
                  We work with 1500 across the UK, targeting learning gaps and helping teens everywhere achieve their goals."
          image="/landing/brand-image-for-feature-2.svg"
          imageAlt="Handpicked tutors"
        />
        <Feature
          reverseRow={false}
          title="Help from our team, every step of the way"
          backgroundColour={colors.bgcolor1}
          textColour={colors.neutral8}
          paragraphSectionOne="Our expert tutor-matching team can pair your child with the perfect tutor for their needs -
                  from subject and level, right down to exam board and personality match.
                  They’re always on hand to listen, answer questions and give you the tailored support you need."
          image="/landing/brand-image-for-feature-3.svg"
          imageAlt="A whole grade"
        />
        <Feature
          reverseRow
          title="Our interactive learning space makes lessons engaging"
          backgroundColour={colors.bgcolor1}
          textColour={colors.neutral8}
          paragraphSectionOne="Lessons are much more than a video call. They all happen in our tailor-made, interactive lesson space.
                  So tutors can bring tricky concepts to life with interactive exercises, draw diagrams as they go,
                  plus annotate homework and practice questions together. It can even make dreaded subjects - dare we say it - fun."
          image="/landing/brand-image-for-feature-4.svg"
          imageAlt="Trusted by teachers"
        />
        <FeaturedBar />
        <FAQs />
        <SubjectsBar />
        <FreeStudyResources />
        <Ratings />
        <CTABanner />
        <Footer />
        <StickyCTA heroPosition={heroPosition} />
        <CookieConsentAutoClose heroPosition={heroPosition} />
      </main>
    </div>
  );
}
